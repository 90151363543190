import { mapState, mapActions } from 'pinia'
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'

export default {
  data() {
    return {
      changingToPlan: 'free'
    }
  },
  computed: {
    ...mapState(useSiteStore, ['currentSite']),
    ...mapState(usePricingStore, ['plan']),
  },
  methods: {
    ...mapActions(useSiteStore, ['saveSitePlan', 'updateSitePricingPlan']),
    reloadSite() {
      setTimeout(() => document.location.reload(), 2000)
    },
    async changePlan() {
      const plan = this.$isPro() ? 'free' : 'paid'
      try {
        await this.saveSitePlan({ plan, preventLoad: true })

        if(plan === 'paid') { this.upgradeSuccess = true }

        this.reloadSite()
        } catch (e) {
          this.$toasts.add({
            type: 'error',
            title: 'Sales Pop disabled!',
            subtitle: 'Your sales pops are disabled.',
            e,
        })
      }
    }
  }
}
